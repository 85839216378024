import 'react-app-polyfill/stable'
import './index.css';
import 'core-js'
import React from 'react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider as ReduxProdiver } from 'react-redux'
import store from './store'
import { createRoot } from 'react-dom/client';


const options = {
  position: 'bottom right',
  timeout: 5000,
  offset: '30px',
  transition: 'scale',
}
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


root.render(
  <ReduxProdiver store={store}>
      <App />
  </ReduxProdiver>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
