import { createStore } from 'redux'
import server from 'src/server';

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)

// const baseUrl = 'http://127.0.0.1'
const baseUrl = '${server}:8080'
export default store
